/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1601px) {

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}

.wrapper {
    width: 1170px;
    &.w126 {width: 980px;}
}

.btn_round {
    line-height: 50px;
}

/* header
----------------------------------------------------------------------------------------------*/
header {
    padding: 20px 0;
    .wrapper {
        width: auto; margin: 0 30px;
    }
    .logo {
        width: 400px; margin-right: 70px;
        &.ind {margin-right: 20px;}
    }
    .rh_head {
        nav {
            a {
                margin-left: 15px; font-size: 14px;
                &.btn_round {padding: 0 16px;}
            }
        }
    }
    &.home {
        padding-top: 40px;
    }
    &.float {
        padding-top: 20px;
        .logo {
            width: 400px;
            &.ind {margin-right: 8px;}
        }
    }

}


.pop_src {
    height: 120px; padding: 30px 0;
    form {
        input[type="text"] {font-size: 46px;}
    }
}

.mnu_wrap {
    @include transform(translateY(-53%)); top: 57%;
    nav {
        ul {
            li {
                a {font-size: 56px;}
                .sub {left: 500px;}
            }
        }
    }
}



/* homepage
----------------------------------------------------------------------------------------------*/

.slideHome {
    .slider {
        .slide {
            .capt_slide {
                h1 {font-size: 54px; line-height: 65px;}
            }
        }
        .slick-dots {width: 1170px;}
    }
    &.contentslider{ 
        width: auto; margin: 0 30px; margin-bottom: 100px;
        .slider{
            .slick-dots{ width: 1170px; }
        }
    }
}
.pack_detail{
    .wrapper.w126 { width: 1100px; }
}
.bannerslide{
    .slideHome.contentslider{ 
        margin: 0 100px; margin-bottom: 100px;
        .slider{
            .slick-dots{ width: 1040px; }
        }
    }
}
.sec_journey {
    .select_journey {
        .sel {
            select {width: 235px;}
        }
    }
}
.sec_why {
    .txt_top {padding: 80px 0 40px;}
}
.slider_service,
.list_desti {
    .slide {width: 280px; margin-right: 15px; min-height: 400px;}
    .slick-arrow {
        &.slick-prev {left: -70px}
        &.slick-next {right: -70px;}
    }
    &.slide_Package {
        .item {width: 280px; margin-right: 15px;}
    }
}




/* middle
----------------------------------------------------------------------------------------------*/
.txt_top {
    h1 {font-size: 45px;}
}

.middle {
    padding: 90px 0 50px;
    .top_pg {
        .txt_top {padding: 80px 0 40px;}
    }
}
.text_inner {
    .lf_txt { width: 355px;}
    .rh_txt {width: 590px;}
}

.sec_service {
    .blok_service {
        .img_lf {width: 650px;}
        .right_dec {width: 550px;}
    }
}
.career_wrap {width: 1170px;}
.form_box {
    .row {
        .field {
            .col_ck {height: auto;}
            .qty_input {
                + span {font-size: 15px; margin-left: 15px;}
                .input_val {height: 45px;}
            }
        }
        &.sum {
            label {margin-bottom: 20px;}
        }
    }
    hr {margin: 32px 0;}
    &.apply {width: auto;}
    &.plan {
        .row {
            .col-2 {
                width: 65%;
                &:nth-child(2) {
                    float: left; margin-top: 10px;
                    .field {margin-left: 165px;}
                }
            }
        }
    } 
    &.register {
        margin-top: -55px;
        .row {
            .field.btn_rw {
                p {font-size: 14px;}
            }
        }
    }

}



.box_signup {
    .right_form {
        width: 680px; padding: 105px 0px 25px 70px;
        &.log_desti {padding-top: 55px;}
    }
    .img {width: 400px;}
}
.list_result {
    .item {
        .img {width: 300px;}
        .dec_result {width: 620px;}
    }
}

.package_sec {
    .left_package {
        width: 700px;
        .box_checkout {
            .boxs {padding: 40px 50px 30px;}
        }
    }
    .right_package {
        width: 360px;
        .box {
            .label_info {padding: 20px 25px;}
            .isi_info {
                padding:  25px 20px 20px 25px;
            }
            &.book_now {
                .isi_info {
                    padding: 25px 20px 20px 25px;
                    .booknow_info {
                        .row {
                            .colmn {
                                &:nth-of-type(2) {width: 30px;}
                                &:nth-of-type(3) {width: 145px;}
                            }
                        }
                        &.inp {
                            .row {margin-bottom: 30px;}
                            .colmn {
                                &:nth-of-type(1) {width: 100%; margin-right: 0; margin-bottom: 12px;}
                                &:nth-of-type(2) {
                                    width: 110px; text-align: right; margin-right: 0;
                                    .qty_input {display: inline-block;}
                                }
                                &:nth-of-type(3) {
                                    width: 190px; margin: 0; margin-left: 10px;
                                    > h6 {text-align: right; font-weight: 600;}
                                }
                            }
                        }
                    }
                }
                .total {
                    margin: 0 -20px -50px -25px;
                }
            }
        }
        .ket_btm {
            text-align: center;
            a.left {float: none; margin-left: 0;}
            .btn_round {display: block; margin-top: 30px; max-width: 120px; float: none; margin: 30px auto 0;}
        }
    }
}

.bnnr_dash {
    overflow: hidden;
    .info_dash {
        .ico_profile {width: 120px; height: 120px;}
        .info_profile {
            margin-left: 45px;
            h4 {font-size: 26px;}

        }
    }
}
.pg_dashboard {
    .box {
        .label_info {padding: 15px 25px;}
        .isi_info {
            padding: 30px 25px;
            .col {
                label {max-width: 130px;}
                p {
                    margin-left: 140px;
                    &.mail {overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
                }
                &.link_edit {margin: 35px -25px -30px;}

            }
            .list-box {
                .box {
                    .img {width: 300px; height: 300px;}
                }
                .toggle {
                    .img {width: 300px; height: 300px; display: block; margin: auto;}
                }
            }
        }
    }
    .box_add {
        h4 {font-size: 26px;}
    }
    .block_lf {
        width: 400px;
    }
    .block_rh {width: 550px;}
}

.box_booking {
    .item_booking {
        .top_book {padding: 10px 25px;}
        .text_booking {
            padding: 25px 25px 30px;
        }
    }
}


/* footer
----------------------------------------------------------------------------------------------*/

footer {
    .top_foot {
        .box:nth-of-type(1) {width: 300px; margin-right: 50px;}
        .mnu_foot {
            width: 610px;
            ul {
                li {
                    &:nth-child(3n) {margin-right: 35px;}
                }
            }
        }
    }
}





} /* end media */


@media (max-width: 1601px)  and (min-width: 1440px) {

header .logo { position: fixed; left: 0; right: 0; margin: auto !important; float: none !important; }

} /* end media */


@media (max-width: 1440px)  and (min-width: 1288px) {

header .rh_head.ind {
    .logo {
        margin-right: 20px;
    }
}
header.float {
    .rh_head {
        &.ind {
            .logo {margin-right: 0;}
        }
    }
}

} /* end media */